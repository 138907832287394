import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../../components/InnerPage';

const DerEinzelneZitate = () => (
    <InnerPage
        title="Zitate: Der Einzelne"
    >
        <IntroHolder
            title="Zitate"
            subtitle="Der Einzelne"
            breadcrumbs={{
                greatgrandparent: 'Woran Bahá’í glauben',
                grandparent: 'Der Einzelne und die Gesellschaft',
                parent: 'Der Einzelne',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="Bahá’u’lláh, Botschaften aus Akka, Tajallíyát">
                    Wissen gleicht den Flügeln für des Menschen Leben, einer
                    Leiter für seinen Aufstieg. Es ist jedermanns Pflicht, sich
                    Wissen zu erwerben. Jedoch sollten solche Wissenschaften
                    studiert werden, die den Völkern auf Erden nützen, nicht
                    solche, die mit Worten beginnen und mit Worten enden. Viel
                    verdanken fürwahr die Völker der Welt den Wissenschaftlern
                    und den Handwerkern.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Das geheimnis göttlicher Kultur">
                    Und Ehre und Würde des einzelnen liegen darin, daß er vor
                    all den Massen der Weltbewohner zu einer Quelle des
                    gesellschaftlichen Wohles wird. Gibt es eine größere Gnade
                    als die, daß ein Mensch, wenn er in sich geht, feststellen
                    darf, daß er, durch göttliche Gunst bestätigt, Frieden und
                    Wohlfahrt, Glück und Nutzen unter seinen Mitmenschen
                    bewirkte? Nein, bei dem einen wahren Gott! Es gibt keine
                    größere Freude, kein vollkommeneres Glück.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Das geheimnis göttlicher Kultur">
                    Gott hat uns Augen gegeben, damit wir uns in der Welt
                    umschauen und alles festhalten, was unsere Kultur und unsere
                    Lebenskunst voranbringt. Er hat uns Ohren gegeben, damit wir
                    zu unserem Nutzen auf die Weisheit der Gelehrten und
                    Philosophen hören und uns aufmachen, ihre Lehren zu fördern
                    und in die Tat umzusetzen. Sinne und Fähigkeiten sind uns
                    verliehen worden, damit wir sie dem Dienst am allgemeinen
                    Wohl weihen, so daß wir, die wir uns durch
                    Wahrnehmungsvermögen und Verstand hoch über alle anderen
                    Lebensformen erheben, uns allezeit und in jeder Hinsicht,
                    bei wichtigen und bei nebensächlichen Anlässen, in normalen
                    oder außergewöhnlichen Verhältnissen, darum mühen, die ganze
                    Menschheit sicher in der unbezwingbaren Feste des Wissens zu
                    versammeln. Unablässig sollen wir neue Grundlagen des
                    menschlichen Glückes legen, fortgesetzt sollen wir neue
                    Werkzeuge schaffen und alte verbessern, um dieses Ziel zu
                    erreichen. Wie erhaben, wie hochgeehrt ist ein Mensch, wenn
                    er sich aufmacht, seinen Pflichten nachzukommen; wie
                    erbärmlich und gemein ist er, wenn er seine Augen vor dem
                    Wohlergehen der Gesellschaft verschließt und sein kostbares
                    Leben damit vergeudet, selbstischen Interessen und
                    persönlichem Nutzen nachzujagen! Der Mensch wird höchstes
                    Glück erlangen und die Zeichen Gottes in der Welt und in der
                    Menschenseele schauen, wenn er auf dem Schlachtroß hehren
                    Bestrebens in die Kampfbahn der Kultur und der Gerechtigkeit
                    prescht.
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DerEinzelneZitate;
